import React, { useState, useRef } from 'react'
import { graphql, Link } from 'gatsby'
import { Layout, JobTypeGrid } from '../components'
import { Search } from '../components'
import {
  LayoutBackground,
  BioButton,
  ContentTeaserCollection,
  BlockContent
} from '../components/shared'
import { Seo } from '../components/base'
import { createPagePath } from '../utils'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import classNames from 'classnames'
import { useCookies } from '../hooks/useCookies'
import * as styles from '../css/organism/bioCareerPreviewPage.module.scss'
import { getLocale, getLocaleUrl } from '../utils/localeUtils'

const CareerPreviewPage = ({ data, pageContext }) => {
  const careerContext = pageContext.careerContext || 1
  const languages = { pageContext }
  const hasGoogleMapsConsent = useCookies('preferencesConsent')
  const [jobCount, setJobCount] = useState(null)
  const [searchLimit, setSearchLimit] = useState()

  const pageContent =
    careerContext === 2
      ? data.sanityTrainingPage.nodes[0]
      : data.sanityCareerPage.nodes[0]

  const {
    seo,
    image,
    headline,
    _rawIntroText,
    doubleTeaser,
    overviewHeadline,
    teaserContent,
    _rawApplicationQuestionText,
    filterButtonText,
    _rawNoSearchResultsText,
    applicationCardHeadline = null,
    applicationCardText = null
  } = pageContent

  const searchResultRef = useRef()

  // sorts the groupped vacancies by jobType title
  // it only needs to get the first vacancy -> jobType.title
  // per group as a reference comparison criteria
  const sortByJobTitle = (a, b) => {
    let titleJobA = a.nodes[0]?.jobType?.title
    let titleJobB = b.nodes[0]?.jobType?.title
    if (!titleJobA || !titleJobB) return 0
    if (titleJobA < titleJobB) return -1
    if (titleJobA > titleJobB) return 1
    return 0
  }

  data.sanityVacancies.group = data.sanityVacancies.group.map(jobTypeGroup => {
    const vacancyNodes = jobTypeGroup.nodes.filter(vacancy => vacancy.market)
    return {
      ...jobTypeGroup,
      doc_count: vacancyNodes?.length,
      nodes: vacancyNodes
    }
  })
  data.sanityVacancies.group.sort(sortByJobTitle)

  const layoutBackgroundHeaderComponent = (
    <div className={styles.layoutBackgroundHeaderComponent}>
      <h2 className={classNames(styles.__headline, 'bioHeading1')}>
        {overviewHeadline}
      </h2>
    </div>
  )

  return (
    <Layout translations={languages}>
      <Seo
        title={seo?.metaTitle}
        description={seo?.metaDescription}
        image={seo?.ogImage?.asset?.url}
      />
      <div className={styles.bioCareerPreviewPage}>
        <section className={styles.__header}>
          <h1 className={styles.__headline}>{headline}</h1>
          <GatsbyImage
            image={getImage(image?.image?.asset)}
            objectFit="contain"
            alt={image?.alt}
          />
          <BlockContent
            data={_rawIntroText}
            renderContainerOnSingleChild
            className={styles.__blockContent}
          />
        </section>
        <LayoutBackground headerComponent={layoutBackgroundHeaderComponent}>
          <div ref={searchResultRef} className={styles.__searchContainer}>
            <Search
              showToTopButton
              index="jobtype"
              initialItems={data.sanityVacancies.group}
              count={jobCount}
              filterButtonText={filterButtonText}
              searchListHeight={searchResultRef?.current?.offsetHeight}
              careerContext={careerContext}
              getSearchLimit={setSearchLimit}
              withLocation
              hasGoogleMapsConsent={hasGoogleMapsConsent}
              hideFilterOnMobile={false}>
              <JobTypeGrid
                count={jobCount}
                searchLimit={searchLimit}
                setJobCount={setJobCount}
                noSearchResultsText={_rawNoSearchResultsText}
                careerContext={careerContext}
                applicationCardHeadline={applicationCardHeadline}
                applicationCardText={applicationCardText}
              />
            </Search>
          </div>
        </LayoutBackground>
        <section className={styles.__teaserSection}>
          <div className={styles.__container}>
            {doubleTeaser?.map((element, index) => (
              <Teaser key={index} teaser={element} />
            ))}
          </div>
        </section>
        <section>
          <BlockContent
            data={_rawApplicationQuestionText}
            renderContainerOnSingleChild
            className={styles.__blockContent}
          />
          <ContentTeaserCollection content={teaserContent} />
        </section>
      </div>
    </Layout>
  )
}

const Teaser = ({ teaser }) => {
  const bgImage = getImage(teaser?.customImage?.image?.asset)
  if (!teaser) return null

  const AnchorWrapper = ({ children, className }) => {
    const locale = getLocale()
    if (teaser?.button?.customLink || teaser?.customFile) {
      return (
        <a
          href={createPagePath({
            item: teaser,
            customPath: teaser?.button?.customPath?.current
          })}
          className={classNames(className)}>
          {children}
        </a>
      )
    } else if (teaser?.button?.customPath?.current) {
      return (
        <Link
          to={getLocaleUrl(locale, teaser?.button?.customPath?.current)}
          className={classNames(className)}>
          {children}
        </Link>
      )
    } else {
      return (
        <Link
          to={getLocaleUrl(locale, `/${teaser?.teaserContent?.slug?.current}`)}
          className={classNames(className)}>
          {children}
        </Link>
      )
    }
  }

  return (
    <AnchorWrapper className={styles.teaser}>
      <BgImage
        image={bgImage}
        className={styles.__imageWrapper}
        style={{ width: '100%' }}>
        <div className={styles.__content}>
          <h3 className={('bioHeading3', styles.__title)}>{teaser.title}</h3>
          <p className={styles.__subline}>{teaser?.subline}</p>
          {teaser.button && (
            <div className={styles.__buttonContainer}>
              <BioButton button={teaser.button} />
            </div>
          )}
        </div>
      </BgImage>
    </AnchorWrapper>
  )
}

export default CareerPreviewPage

export const careerQuery = graphql`
  query ($language: String! = "de_de") {
    sanityCareerPage: allSanityCareerPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        title
        seo {
          ...SeoQuery
        }
        image {
          ...ImageObjectQuery
          alt
        }
        headline
        _rawIntroText
        overviewHeadline
        _rawApplicationQuestionText
        doubleTeaser {
          ...TeaserContentQuery
        }
        teaserContent {
          ...TeaserContentQuery
        }
        filterButtonText
        _rawNoSearchResultsText
        applicationCardHeadline
        applicationCardText
      }
    }
    sanityTrainingPage: allSanityTrainingPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        title
        seo {
          ...SeoQuery
        }
        image {
          ...ImageObjectQuery
        }
        headline
        _rawIntroText
        overviewHeadline
        _rawApplicationQuestionText
        doubleTeaser {
          ...TeaserContentQuery
        }
        teaserContent {
          ...TeaserContentQuery
        }
        filterButtonText
        _rawNoSearchResultsText
      }
    }
    sanityVacancies: allSanityVacancy(
      filter: {
        jobType: { context: { eq: 1 }, hidden: { ne: true } }
        hidden: { ne: true }
      }
      sort: { fields: [jobType___title], order: ASC }
    ) {
      group(field: jobType____id) {
        jobType: fieldValue
        doc_count: totalCount
        nodes {
          jobType {
            _id
            title
            altTitle
            slug: slug {
              current
            }
          }
          market {
            ... on SanityBranch {
              name
              address {
                street
                city
                zip
              }
            }
            ... on SanityMarket {
              name
              address {
                street
                city
                zip
              }
            }
          }
        }
      }
    }
  }
`
