// extracted by mini-css-extract-plugin
export var __blockContent = "bioCareerPreviewPage-module--__blockContent--9148d";
export var __buttonContainer = "bioCareerPreviewPage-module--__buttonContainer--6fd83";
export var __container = "bioCareerPreviewPage-module--__container--ffa2e";
export var __content = "bioCareerPreviewPage-module--__content--b2de0";
export var __header = "bioCareerPreviewPage-module--__header--966f8";
export var __headline = "bioCareerPreviewPage-module--__headline--84ab8";
export var __imageWrapper = "bioCareerPreviewPage-module--__imageWrapper--5c98c";
export var __searchContainer = "bioCareerPreviewPage-module--__searchContainer--28362";
export var __subline = "bioCareerPreviewPage-module--__subline--fd736";
export var __teaserSection = "bioCareerPreviewPage-module--__teaserSection--b934d";
export var __title = "bioCareerPreviewPage-module--__title--1defe";
export var bioCareerPreviewPage = "bioCareerPreviewPage-module--bioCareerPreviewPage--e2a8d";
export var layoutBackgroundHeaderComponent = "bioCareerPreviewPage-module--layoutBackgroundHeaderComponent--44b51";
export var teaser = "bioCareerPreviewPage-module--teaser--450e2";